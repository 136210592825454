.navbar {
  /*background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));*/
  background-color: '#00497A'; /* Para poner el color negro en la SideBar*/
  height: rem(800px);
  width: rem(250px) !important; /* Ajusta el ancho aquí */
  padding: var(--mantine-spacing-md);
  padding-bottom: 0 ;
  display: flex ;
  flex-direction: column;
  border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)) ;

}

.header {
  padding: var(--mantine-spacing-md) ;
  padding-top: 0 ;
  margin-left: calc(var(--mantine-spacing-md) * -1) ;
  margin-right: calc(var(--mantine-spacing-md) * -1) ;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white)) ;
  border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)) ;

}

.links {
  flex: 1;
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
}

.linksInner {
  padding-top: var(--mantine-spacing-sm);
  padding-bottom: var(--mantine-spacing-xl);
}

.footer {
  position: fixed;
  bottom: 0;
  padding-bottom: calc(3%);
  display: flex; /* Utiliza flexbox para centrar el contenido */
  justify-content: center; /* Centra el contenido horizontalmente */
  background-color:#002F4F; /* Cambia el color de fondo según lo necesites */
  border-top: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}


/* footer antes 
  .footer {
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
  border-top: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

*/