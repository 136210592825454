/*
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css');
*/


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Arimo',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css');



*{
  font-family: 'Arimo', sans-serif !important;
}
.h-screen {
  height: 100vh;
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}


.loader-parent{
  position: fixed;
  inset: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 9999 !important;
  background-color: rgba(43, 43, 43, 0.664);
}

.w-50{
  width: 50%;
}
.w-100{
  width: 100%;
}

i{
  font-size: 1.4rem;
  cursor: pointer;
}

input{
   border : 1px solid rgb(79, 83, 83);
}

/*
.container{
  height: 85vh !important;
}
*/

/*.mantine-xoj88x{
  height: 100% !important;
  overflow: scroll !important;
}*/

.auth{
  background-color: #F0F0F0;
}

.my-custom-table th {
  background-color: #fffdfd;
  /* Color de fondo para encabezados */
  border-bottom: 1px solid #ddd;
  /* Línea divisoria en la parte inferior de las celdas del encabezado */
  text-align: center;
  /* Centra el texto */
  background-color: hsl(0, 0%, 100%);
  /* Color de fondo */
  color: rgb(0, 0, 0);
  /* Color del texto */
}

.my-custom-table td {
  border: 1px solid #ddd;
  /* Define el color y grosor de la línea divisiva */
  padding: 8px;
  text-align: center;
}

.my-custom-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9; /* Fondo de filas impares */
}

.my-custom-table tbody tr:hover {
  background-color: #e0e0e0; /* Color de fondo al pasar el ratón sobre una fila */
}

.native-select-container {
  width: 60%;
  position: relative;
}

.native-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32'%3E%3Cpath d='M16 24l-8-8 1.41-1.41L16 21.17l6.59-6.58L24 16z'/%3E%3C/svg%3E") no-repeat right 10px center/10px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.native-select:focus {
  outline: none;
  border-color: #007bff;
}

.AccordionControl {
  background-color: #00497A; /* Cambia este valor al color que prefieras */
  color: white;
}

.AccordionControl:hover {
  background-color: #00497A; /* Mantener el mismo color de fondo en hover */
}

.AccordionPanel {
  background-color: white;
  border: 1px solid rgb(242, 241, 241);
  padding: 10px;
}

.fullscreen-background {
  background-color: white;
}



